/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppBar, Button, Grid, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SAppBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.colors.navbarBg};
    color: ${({ theme }) => theme.colors.navbarFg};
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

    .MuiToolbar-root {
      min-height: 56px;
    }
  }
`;

interface SMenuItemsProps {
  $supercarteira?: boolean;
}

export const SMenuItems = styled(Grid)<SMenuItemsProps>`
  && {
    --supercarteira-spacing-between-nav-itens: 0;
    row-gap: 10px;
    justify-content: start;
    justify-content: ${({ $supercarteira }) =>
      $supercarteira ? "space-evenly" : "start"};
    width: 100%;
    max-width: calc(1920px - (24px + 191px));

    > * {
      padding-left: 45px;
      padding-right: ${({ $supercarteira }) =>
        $supercarteira
          ? "var(--supercarteira-spacing-between-nav-itens)"
          : "45px"};
    }

    @media (max-width: 920px) {
      > * {
        padding-left: 15px;
        padding-right: 15px;
      }
      flex-wrap: auto;
      align-items: flex-start;
    }
  }
`;

interface SMenuItemPropsType {
  $selected?: boolean;
}

export const SMenuItem = styled(Typography)<SMenuItemPropsType>`
  && {
    color: ${({ theme }) => theme.colors.navbarFg};
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 700;
    cursor: pointer;
    height: 40px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: ${({ theme, $selected }) =>
      $selected
        ? `3px solid ${theme.colors.navbarSelectionLine}`
        : "3px solid rgba(0,0,0,0)"};

    && {
      @media (max-width: 920px) {
        white-space: nowrap;
      }
    }
  }
`;

export const SLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.navbarFg};
  }
`;

export const SToolbar = styled(Toolbar)`
  && {
    margin-right: 0;
    padding-right: 0 !important;
    display: flex;
    align-items: flex-end;

    @media (max-width: 920px) {
      display: flex;
      align-items: center;
    }
  }
`;

type SLogoImgProps = {
  $padding: string;
};

export const SLogoImg = styled.img<SLogoImgProps>`
  && {
    margin-top: 3px;
    margin-bottom: 3px;
    padding: ${({ $padding }) => $padding ?? "unset"};

    @media (max-width: 920px) {
      height: unset;
      max-height: 80px;
      width: 30%;
      max-width: 150px;
    }
  }
`;

interface SLogoutButtonPropsType {
  $isFloating: boolean;
}

export const SLogoutButton = styled(Button)<SLogoutButtonPropsType>`
  && {
    position: ${({ $isFloating }) => ($isFloating ? "absolute" : "unset")};
    top: ${({ $isFloating }) => ($isFloating ? "0" : "unset")};
    right: ${({ $isFloating }) => ($isFloating ? "0" : "unset")};
    margin-top: 3px;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: ${({ theme }) => theme.colors.navbarBg};
    color: ${({ theme }) => theme.colors.navbarSelectionLine};
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.addButtonHoverFg};
      color: ${({ theme }) => theme.colors.addButtonSecondary};
    }
  }
`;
