import React from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  SBuildIcon,
  SIconWrapper,
  SContainer,
  SHeading,
  SLink,
  SText,
} from "./styles";

export const LearnToUse: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formatMessageContact = (): JSX.Element => {
    const { email, whatsapp } = theme.learnToUse;
    const parts = t("learnToUse.messagePart2").split(/{}/g);

    return (
      <>
        {parts[0]}
        <SLink target="_blank" href={`mailto:${email}`}>
          {t("contact.email")}
        </SLink>
        {parts[1]}
        <SLink
          target="_blank"
          href={`${process.env.REACT_APP_WHATSAPP_LINK_DOMAIN}${whatsapp}`}
        >
          {t("contact.whatsApp")}
        </SLink>
        {parts[2]}
      </>
    );
  };

  return (
    <SContainer>
      <SIconWrapper>
        <SBuildIcon />
      </SIconWrapper>

      <SHeading>{t("learnToUse.title")}</SHeading>

      <SText>
        {t("learnToUse.messagePart1")} <br />
        {formatMessageContact()} <br />
        {t("learnToUse.messagePart3")}
      </SText>
    </SContainer>
  );
};
