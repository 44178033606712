import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useMount } from "react-use";
import { useTheme } from "styled-components";
import { useFetchUserData } from "./api";
import { NavBar } from "./components";
import { AuthenticationBackdrop } from "./components/shared";
import {
  AssetComparator,
  LearnToUse,
  LoginPage,
  PortfolioAnalysis,
  PortfolioComposer,
  PortfolioEditorPage,
  PortfolioShared,
  SuperCarteiraImporterPage,
} from "./pages";
import {
  useCurrency,
  useLanguage,
  useSuperCarteiraRedirections,
} from "./store";
import { getCredentials } from "./utils";

const compareAssetsSharedLinkPattern = /asset#assets/;
const comparePortfolioSharedLinkPattern = /shared/;

const App: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loading, fetch: fetchAndAuthenticateUser } = useFetchUserData();
  const { gotoFeatureWithUserData } = useSuperCarteiraRedirections();

  const [isAppBooted, setIsAppBooted] = useState(false);

  const { i18n } = useTranslation();
  const { setCurrency } = useCurrency();
  const [, setLanguage] = useLanguage();

  const defaultCurrency = theme.currency.default;
  const defaultLanguage = theme.lang.default;

  useMount(() => {
    setCurrency(defaultCurrency);

    i18n.changeLanguage(defaultLanguage);
    setLanguage(defaultLanguage);
  });

  useMount(() => {
    const initializeApp = async (): Promise<void> => {
      // Case with Auth
      if (theme.auth.useAuth) {
        const credentials = getCredentials(theme);

        // Case invalid credentials
        if (!credentials.valid) {
          navigate("/login");
          return;
        }

        // Case valid credentials
        const userData = await fetchAndAuthenticateUser(credentials.login);

        // Credentials were valid but request for userData failed
        if (userData === null) {
          navigate("/login");
          return;
        }

        setIsAppBooted(true);

        // Credentials were valid and request for userData succeeded

        if (compareAssetsSharedLinkPattern.test(window.location.href)) {
          return;
        }

        gotoFeatureWithUserData("supercarteira", userData);
        return;
      }

      // Case without Auth
      if (
        !compareAssetsSharedLinkPattern.test(window.location.href) &&
        !comparePortfolioSharedLinkPattern.test(window.location.href)
      ) {
        navigate(theme.navbar.startingRoute);
      }

      setIsAppBooted(true);
    };

    initializeApp();
  });

  const ifAppBooted = (component: React.ReactNode): React.ReactNode => {
    if (isAppBooted) {
      return component;
    }

    return <AuthenticationBackdrop open />;
  };

  return (
    <>
      {theme.navbar.show && pathname !== "/login" && <NavBar />}

      {theme.auth.useAuth && loading && (
        <AuthenticationBackdrop open={loading} />
      )}

      <Routes>
        <Route path="/" element={ifAppBooted(<PortfolioComposer />)} />
        <Route path="login" element={<LoginPage />} />
        <Route path="editor" element={ifAppBooted(<PortfolioEditorPage />)} />
        <Route path="analysis" element={ifAppBooted(<PortfolioAnalysis />)} />
        <Route path="shared" element={ifAppBooted(<PortfolioShared />)} />
        <Route path="learn-to-use" element={ifAppBooted(<LearnToUse />)} />
        <Route path="asset" element={ifAppBooted(<AssetComparator />)}>
          <Route
            path=":assetSharedLink"
            element={ifAppBooted(<AssetComparator />)}
          />
        </Route>
        <Route path="import">
          <Route
            path="previdencia"
            element={<SuperCarteiraImporterPage mode="previdencia" />}
          />
          <Route
            path="supercarteira"
            element={<SuperCarteiraImporterPage mode="supercarteira" />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
