import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  SGridCard,
  SGridContainer,
  SGridRow2,
  SGridRow3,
  LeftInputColumn,
  RightInputColumn,
  SGridRow4,
  SAddAssets,
  SAddIcon,
  SRemoveButton,
  SRemove,
} from "./styles";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { PortfolioSummaryDonutChart } from "./PortfolioSummaryGraph/PortfolioSummaryGraph";
import { PortfolioName } from "./PortfolioName/PortfolioName";
import { PortfolioTotalAmount } from "./PortfolioTotalAmount/PortfolioTotalAmount";
import { PortfolioEditorAssetsList } from "./PortfolioEditorAssetsList/PortfolioEditorAssetsList";
import { PortfolioSummaryDonutChartLegend } from "./PortfolioSummaryGraphLegend/PortfolioSummaryGraphLegend";
import { BalancingButton } from "./BalancingButton/BalancingButton";
import { useCurrentPortfolio, useSideEditor } from "../../../store";

export const PortfolioEditor: React.FC = () => {
  const { removeCurrentPortfolio, isLastPortfolio } = useCurrentPortfolio();
  const { setPortfolioBeingEdited } = useSideEditor();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleAddAsset = (): void => {
    navigate("../", { replace: true });
  };

  const showBalacingButton = theme?.portfolios?.balancingButton.show;

  const handleRemovePortfolio = (): void => {
    if (isLastPortfolio) {
      navigate("../");
    }
    setPortfolioBeingEdited(null);
    removeCurrentPortfolio();
  };
  return (
    <SGridContainer>
      <SGridCard>
        <Header />
        <SGridRow2>
          <PortfolioSummaryDonutChart />
          <PortfolioSummaryDonutChartLegend />
        </SGridRow2>
        <SGridRow3>
          <LeftInputColumn>
            <PortfolioName />
          </LeftInputColumn>
          <RightInputColumn>
            <PortfolioTotalAmount />
            {showBalacingButton ? <BalancingButton /> : null}
          </RightInputColumn>
        </SGridRow3>
        {location.pathname === "/analysis" ? (
          <SGridRow4>
            <SRemoveButton onClick={handleRemovePortfolio}>
              <SRemove />
            </SRemoveButton>
            <SAddAssets onClick={handleAddAsset}>
              <SAddIcon />
              {t("portfolioEditorAddAsset")}
            </SAddAssets>
          </SGridRow4>
        ) : null}
      </SGridCard>
      <PortfolioEditorAssetsList />
      <Footer />
    </SGridContainer>
  );
};
