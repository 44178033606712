import logo from "../assets/kurtosys.png";
import { ThemeType } from "./defaultTheme";

const themeProps = {
  auth: {
    useAuth: false,
    checkTokenExpirationOnClientSide: false,
  },
  lang: {
    default: "en",
  },
  currency: {
    default: "EUR",
  },
  login: {
    logo: {
      show: false,
      img: logo,
    },
    checkbox: {
      checked: "#00A9FE",
      unchecked: "rgba(51, 51, 51, 0.25)",
    },
    splashMessageLine1: "login.kurtosys.splash1",
    splashMessageLine2: "login.kurtosys.splash2",
    insertMailMessage: "login.kurtosys.insertMail",
    okButtonMessage: "login.kurtosys.ok",
    errorMessage: "login.kurtosys.error",
    invalidEmailMessage: "login.kurtosys.invalidMail",
    rememberThisDevice: "login.kurtosys.rememberThisDevice",
    successMessage: "login.kurtosys.success",
    authErrorMessage: "login.kurtosys.authError",
  },
  fonts: {
    primary: "Open Sans, sans-serif",
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
    quote: "Georgia, serif",
  },
  logo: {
    img: logo,
    height: "78px",
    padding: "0px 0px",
    align: "center",
    show: true,
  },
  navbar: {
    show: true,
    startingRoute: "/asset",
    supercarteiraButtons: false,
  },
  localeSettings: {
    showSettingsGearButton: false,
    showInsideLeftColumn: false,
  },
  delay: {
    textInput: 1000,
    numberInput: 1250,
  },
  assetSearchShowAllFromSelectedCategory: false,
  assetSearch: {
    funds: {
      show: true,
    },
    stocks: {
      show: true,
    },
    fixedIncome: {
      show: false,
    },
    valorization: {
      show: false,
    },
    diversification: {
      show: false,
    },
    stability: {
      show: false,
    },
    antifragility: {
      show: false,
    },
    others: {
      show: false,
    },
    createAsset: {
      show: false,
    },
  },
  benchmarks: {
    primary: {
      quantity: 5,
    },
    others: {
      show: false,
    },
  },
  portfolios: {
    singlePortfolioMode: false,
    addPortoflio: {
      show: true,
    },
    namePrefix: {
      useCustom: false,
      name: "",
    },
    balancingButton: {
      show: true,
    },
  },
  portfolioSummary: {
    compactList: false,
    noCardIcons: false,
  },
  copySharedLinkButton: {
    show: true,
  },
  generateQRCodeButton: {
    show: true,
  },
  savePortfolioButton: {
    show: false,
  },
  chartExportingButton: {
    show: true,
  },
  defaultPeriod: {
    useCustom: false,
    key: "",
  },
  return: {
    showReturnButtons: false,
  },
  colors: {
    navbarBg: "white",
    navbarFg: "#071935",
    navbarSelectionLine: "#071935",
    textDefault: "#071935",
    textFaded: "rgba(37, 64, 87, 0.5)",
    textDark: "#254057",
    textGray: "rgba(51, 51, 51, 0.6)",
    textWhite: "#FFFFFF",
    textBlack: "#000000",
    textDarkGray: "#333333",
    bgPrimary: "white",
    bgSecondary: "gray",
    bgGray: "#F1F3F5",
    iconCyan: "#00A9FE",
    selectedBlue: "#09B4FF",
    selectedLightBlue: "#D9F2FF",
    funds: "#d73d95",
    stocks: "#4bba76",
    fixedIncome: "#BE56F0",
    pension: "#32A32A",
    valorization: "#DF0B0B",
    diversification: "#604AF0",
    stability: "#F09C62",
    antifragility: "#BE56F0",
    others: "#32A32A",
    createAsset: "#3E85F0",
    leftover: "rgba(51, 51, 51, 0.6)",
    custom: "#3E85F0",
    addButtonPrimary: "#071935",
    addButtonSecondary: "rgba(37,64,87,0.48)",
    addButtonText: "#FFFFFF",
    addButtonHoverFg: "#FFFFFF",
    removeButtonPrimary: "#071935",
    removeButtonSecondary: "rgba(223, 11, 11, 0.48)",
    removeButtonText: "#FFFFFF",
    removeButtonHoverFg: "#FFFFFF",
    cardBorder: "#F2F1F6",
    cardBg: "#FFFFFF",
    cardBgSelected: "#D9F2FF",
    cardCheckboxChecked: "#00A9FE",
    cardCheckboxUnchecked: "rgba(51, 51, 51, 0.25)",
    periodButtonDark: "#333333",
    periodButtonLight: "#FFFFFF",
    periodButtonSelected: "#071935",
    periodButtonSelectedFg: "#FFFFFF",
    periodButtonHoverFg: "#FFFFFF",
    periodButtonHover: "#476885",
    portfolioButtonHover: "#EFF1F3",
    accordionTitleBg: "#071935",
    accordionTitleFg: "white",
    alertRed: "#DF0B0B",
    showAssetsButtonLight: "#FFFFFF",
    showAssetsButtonHover: "#476885",
    showAssetsButtonHoverFg: "#FFFFFF",
    showAssetsButtonDark: "#333333",
    showAssetsButtonSelected: "#071935",
    showAssetsButtonSelectedFg: "#FFFFFF",
    bgQuantumBanner: "#071935",
    addAssetBorder: "#00a9fe",
    addAssetTextColor: "#071935",
    addAssetBackground: "#D9F2FF",
    snackbarMessageSuccess: "#27618F",
    snackbarMessageError: "#DF0B0B",
    shareButtonLight: "#FFFFFF",
    shareButtonHover: "#071935",
    shareButtonDark: "#071935",
    shareButtonHoverFg: "#FFFFFF",
    assets: [
      "#0342e4",
      "#31ff9c",
      "#6641f3",
      "#feb979",
      "#2c6145",
      "#CFE9EB",
      "#C9C9C9",
      "#8ED1E5",
      "#DCFFC7",
      "#D1B7EB",
      "#BAD4FF",
      "#74A2ED",
      "#939393",
      "#A456F4",
      "#ADE38E",
      "#BAD8FF",
      "#1F2A4D",
      "#6F27B8",
      "#4B4B4B",
      "#81C25C",
    ],
    deletePortfolioRed: "#DF0B0B",
    loginPageBg: "#FFFFFF",
    loginCardBg: "#0A1916",
    loginCardFg: "#FFFFFF",
    loginCardBorder: "#D89B5E",
    loginCardInsert: "#D89B5E",
    loginCardEmailBg: "0A1916",
    loginCardEmailFg: "#FFFFFF",
    loginCardOkButtonFg: "#FFFFFF",
    loginCardOkButtonBg: "#D89B5E",
  },
  learnToUse: {
    title: "learnToUse.title",
    renderRoute: false,
    messagePart1: "learnToUse.messagePart1",
    messagePart2: "learnToUse.messagePart2",
    messagePart3: "learnToUse.messagePart3",
    email: "",
    whatsapp: "",
  },
};

export const kurtosysTheme: ThemeType = themeProps;
