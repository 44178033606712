import { Grid } from "@mui/material";
import { Construction as BuildIcon } from "@mui/icons-material";
import styled from "styled-components";

export const SContainer = styled(Grid)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }
`;

export const SIconWrapper = styled.div`
  background-color: #f8f0e5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SBuildIcon = styled(BuildIcon)`
  color: #e38b29;
  font-size: 40px !important;
`;

export const SHeading = styled.h1`
  font-size: 1.75rem;
  font-weight: 500;
  color: #2d2d2d;
  margin: 0;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(227, 139, 41, 0) 0%,
      rgba(227, 139, 41, 0.3) 50%,
      rgba(227, 139, 41, 0) 100%
    );
  }
`;

export const SText = styled.p`
  padding: 30px;
  text-align: center;
  font-size: 1rem;
`;

export const SLink = styled.a`
  color: #333;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    color: #000;
  }
`;
